<template>
  <section>
    <Navbar />
    <div class="columns">
      <SideBarHome class="menu" />
      <div class="container">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'filter']" />
            <h2 class="tituloAtendimento">
              Turmas Futuras
            </h2>
          </div>
        </vs-row>
        <vs-row class="pd-t">
          <vs-col>
            <fieldset>
              <legend>Selecione a Filial <b style="color: red;">*</b></legend>
              <vs-select
                class="selectExample"
                v-model="selectedUnit"
                placeholder="Selecione"
                @change="getFutureClasses()"
              >
                <vs-select-item
                  :key="item.CODFILIAL"
                  :value="item.CODFILIAL"
                  :text="item.POLO"
                  v-for="item in units"
                />
              </vs-select>
            </fieldset>
          </vs-col>
        </vs-row>

        <div v-if="selectedUnit != null">
          <div v-if="futureClasses != null">
            <vs-row v-if="futureClasses == ''">
              <p>Nenhuma informação encontrada</p>
            </vs-row>

            <vs-row class="pd-table" v-else>
              <vs-col>
                <vs-table
                  class="table_intranet"
                  max-items="20"
                  hoverFlat
                  pagination
                  search
                  :data="futureClasses"
                >
                  <template slot="thead">
                    <vs-th class="table_th"> TURMA/MATRICULAS/VAGAS </vs-th>
                    <vs-th class="table_th"> TURMA </vs-th>
                    <vs-th class="table_th"> CURSO/DISCIPLINA </vs-th>
                    <vs-th class="table_th"> PROFESSOR </vs-th>
                    <vs-th class="table_th"> DATA/HORA </vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr
                      class="table_body"
                      :key="index"
                      v-for="(i, index) in data"
                    >
                      <vs-td>
                        {{ i.COD_TURMA }} / {{ i.MATRICULADOS }} /
                        {{ i.VAGAS }}
                      </vs-td>

                      <vs-td>
                        {{ i.TURMA }}
                      </vs-td>

                      <vs-td> {{ i.CURSO }} / {{ i.DISCIPLINA }} </vs-td>

                      <vs-td>
                        {{ i.PROFESSOR }}
                      </vs-td>

                      <vs-td>
                        {{ i.DT_INICIO }} / {{ i.HORA_INICIAL_HR }}
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </vs-col>
            </vs-row>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Units from "../../services/units";

export default {
  data() {
    return {
      units: null,
      error: null,
      futureClasses: null,
      selectedUnit: null,
    };
  },

  mounted() {
    new Units()
      .getUnits()
      .then(({ data }) => {
        this.units = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });
  },

  methods: {
    getFutureClasses() {
      this.$vs.loading({ text: "Carregando..." });
      new Units()
        .getFutureClasses(this.selectedUnit)
        .then(({ data }) => {
          this.futureClasses = Array.isArray(data) ? data : [data];
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
          }
          this.$vs.loading.close();
        });
    },
  },
};
</script>

<style scoped>
tr:nth-child(even) {
  background-color: #d4d4d4;
}
</style>
