export default class{
    constructor() {
        this.instance = 
        [ 
            {
                header: "Administrativo",
                rule: "isLogged",
                submenu: [
                    {
                        index: "2.1",
                        icon: "newspaper",
                        url: "/administrativo/acontecimentos",
                        title: "Acontecimentos",
                    },      
                    {
                        index: "2.2",
                        icon: "graduation-cap",
                        url: "/administrativo/capacitacao",
                        title: "Capacitação",
                    }, 
                    {
                        index: "2.3",
                        icon: "house-user",
                        url: "/administrativo/manutencoes",
                        title: "Agenda Manutenções",
                    },              
                ]
            },
            {
                header: "Comercial",
                rule: "isCommercial",
                submenu: [
                    {
                        index: "3.1",
                        icon: "business-time",
                        url: "/comercial/tempo-real",
                        title: "QI Tempo Real",
                        target: "",
                        rule: "isCommercial",
                    },
                ]
            },
            {
                header: "Educacional",
                rule: "isLogged",
                submenu: [
                    {
                        index: "4.1",
                        icon: "book",
                        url: "/educacional/ocupacao-de-salas",
                        title: "Ocupação de Sala",
                        target: "",
                        rule: "isLogged",
                    },
                    {
                        index: "4.2",
                        icon: "filter",
                        url: "/educacional/turmas-futuras",
                        title: "Turmas Futuras",
                        target: "",
                        rule: "isLogged",
                    },
                    {
                        index: "4.3",
                        icon: "chalkboard-teacher",
                        url: "/educacional/docentes",
                        title: "Docentes",
                        target: "",
                        rule: "isSecretary",
                    },
                    {
                        index: "4.4",
                        icon: "file-signature",
                        url: "/educacional/processo-seletivo-docente",
                        title: "Processos Seletivos de Docentes",
                        target: "",
                        rule: "isManager",
                    },
                    {
                        index: "4.5",
                        icon: "house-user",
                        url: "/educacional/responsavel-filial",
                        title: "Responsáveis por Filial",
                        target: ""
                    },
                    {
                        index: "4.6",
                        icon: "business-time",
                        url: "/educacional/tempo-real",
                        title: "QI Tempo Real",
                        target: "",
                        rule: "isManager",
                    },
                    {
                        index: "4.7",
                        icon: "house-user",
                        url: "/educacional/disciplinas-em-producao",
                        title: "Disciplinas em Produção",
                        target: "",
                    },
                ]
            },
            /*{
                header: "PJ/RPA",
                rule: "isLogged",
                submenu: [
                    {
                        index: "5.1",
                        icon: "print",
                        url: "/rpa/emissao-de-documento",
                        title: "Emissão de Documento",
                        target: "",
                        rule: "isLogged",
                    },
                ]
            },*/
            {
                header: "RH",
                rule: "isOpenRH",
                submenu: [
                    {
                        index: "6.1",
                        icon: "business-time",
                        url: "/rh/controle-de-comissao",
                        title: "Controle de Comissão",
                        target: "",
                        rule: "isOpenRH",
                    },
                    /*{
                        index: "6.2",
                        icon: "times-circle",
                        url: "/rh/desligamento",
                        title: "Desligamento",
                        target: "",
                        rule: "isOpenDesFunc",
                    },*/
                ]
            },
        ]
    }
    getData(){
        return this.instance;
    }
}