<template>
  <section>
    <NavBarAtendimentos />
    <div class="columns">
      <SideBarAtendimentos class="menu" />
      <div>
        <div class="container">
          <vs-row vs-w="12" class="topo">
            <div class="titulo">
              <font-awesome-icon :icon="['fas', 'certificate']" />
              <h2 class="tituloAtendimento">
                 Certificados
              </h2>
            </div>
          </vs-row>
          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Tipo de Atendimento <b style="color: red;">*</b></legend>
                <vs-select
                  class="selectExample"
                  placeholder="Selecione um Tipo de Atendimento"
                  v-model="typeSelected"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in options"
                  />
                </vs-select>
              </fieldset>
            </vs-col>  
          </vs-row>                      
        </div>

        <AlunoDestaque v-if="typeSelected == 1" />
        <LaureaAcademica v-if="typeSelected == 2" />
        <Monitoria v-if="typeSelected == 3" />
        <Palestra v-if="typeSelected == 4" />
        <Palestrante v-if="typeSelected == 5" />
        <Parcial v-if="typeSelected == 6" />
        <QiFly v-if="typeSelected == 7" /> 
        <QiIdeias v-if="typeSelected == 8" />        
      </div>
    </div>    
  </section>
</template>

<script>
import AlunoDestaque from "./FormCertificado/AlunoDestaque.vue";
import LaureaAcademica from "./FormCertificado/LaureaAcademica.vue";
import Monitoria from "./FormCertificado/Monitoria.vue";
import Palestra from "./FormCertificado/Palestra.vue";
import Palestrante from "./FormCertificado/Palestrante.vue";
import Parcial from "./FormCertificado/Parcial.vue";
import QiIdeias from "./FormCertificado/QiIdeias.vue";
import QiFly from "./FormCertificado/QiFly.vue";

export default {
  name: "AjusteDiarioDeClasse",
  components: {
    AlunoDestaque,
    LaureaAcademica,
    Monitoria,
    Palestra,
    Palestrante,
    Parcial,
    QiIdeias,
    QiFly
  },
  data: () => ({
    typeSelected: "",
    options: [
      { text: "Certificado Aluno Destaque", value: 1 },
      { text: "Certificado de Láurea Acadêmica", value: 2 },
      { text: "Certificado de Monitoria", value: 3 },
      { text: "Certificado de Palestra", value: 4 },
      { text: "Certificado de Palestrante", value: 5 },
      { text: "Certificado Parcial", value: 6 },
      { text: "Certificado QI Fly", value: 7 }, 
      { text: "Certificado QI IDEIAS", value: 8 },       
    ],
  }),
};
</script>
