import { https } from "./api";

export default class {
    constructor() {
        this.instance = https('units')
    }

    getAllRooms = (codFilial) => {
        return this.instance({
            method: 'GET',
            url: `/allRooms/${codFilial}`,
            timeout: 60000
        })
    }

    getUnitManagerAttendantCode = (codFilial) => {
        return this.instance({
            method: 'GET',
            url: `/${codFilial}/managerAttendantCode`,
            timeout: 60000
        })
    }
    
    getFutureClasses = (codFilial) => {
        return this.instance({
            method: 'GET',
            url: `/${codFilial}/futureClasses`,
        })
    }
    
    getOccupancyClasses = (codFilial, date) => {
        return this.instance({
            method: 'GET',
            url: `/${codFilial}/occupancyClasses/${date}`,
        })
    }
    
    getUnits = () => {
        return this.instance({
            method: 'GET',
            url: '/',
            timeout: 60000
        });
    }

    getAllCourses = () => {
        return this.instance({
            method: 'GET',
            url: '/allCourses',
            timeout: 60000
        })
    }

    getCheckinDays = (timeCourseId, unitId) => {
        return this.instance({
            method: 'GET',
            url: `/${unitId}/checkinDays/${timeCourseId}`,
            timeout: 60000
        })
    }

    getSubtitleQuery = () => {
        return this.instance({
            method: 'GET',
            url: '/subtitleQuery',
            timeout: 60000
        })
    }

    getQueues = (codAtendente) => {
        return this.instance({
            method: 'GET',
            url: `/queues/${codAtendente}`,
            timeout: 60000
        })
    }

    getJobsUnits = (codFilial) => {
        return this.instance({
            method: 'GET',
            url: `/jobsUnits/${codFilial}`,
        })
    }

    getAffiliateAndResponsible = () => {
        return this.instance({
            method: 'GET',
            url: '/affiliateAndResponsible',            
        })
    }
}