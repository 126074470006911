<template>
  <section>
    <Navbar />
    <div class="columns">
      <SideBarHome class="menu" />
      <div class="container">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'times-circle']" />
            <h2 class="tituloAtendimento">Desligamento</h2>
          </div>
        </vs-row>

        <div>
          <div>
            <ValidationObserver ref="observer2" v-slot="{ invalid }">
              <vs-row class="pd-t">
                <vs-col>
                  <fieldset class="search">
                    <legend>
                      Informe o Funcionário <b style="color: red">*</b>
                    </legend>
                    <ValidationProvider name="Funcionario" rules="required">
                      <vs-input
                        @keyup.enter="getInfoFunc(funcInfo)"
                        v-model="funcInfo"
                        class="selectExampleRA"
                        placeholder="Digite o Funcionário (Usuário/CPF/Email)"
                      />
                    </ValidationProvider>
                    <vs-button
                      color="primary"
                      class="buscar"
                      type="filled"
                      :disabled="invalid"
                      @click="getInfoFunc(funcInfo)"
                    >
                      <font-awesome-icon :icon="['fas', 'search']" />
                      Buscar
                    </vs-button>
                  </fieldset>
                </vs-col>
              </vs-row>
            </ValidationObserver>
          </div>
        </div>

        <div v-if="readyForm == true">
          <div v-if="infoF != null">
            <vs-row v-if="infoF == ''">
              <p style="margin-left: 15px; margin-bottom: 10px">
                Nenhuma informação encontrada
              </p>
            </vs-row>

            <vs-row v-else class="pd-table">
              <vs-col>
                <fieldset>
                  <vs-table class="table_intranet" hoverFlat :data="infoF">
                    <template slot="thead">
                      <vs-th class="table_th"> STATUS </vs-th>
                      <vs-th class="table_th"> FUNCIONÁRIO </vs-th>
                      <vs-th class="table_th"> DT. NASCIMENTO </vs-th>
                      <vs-th class="table_th"> CPF </vs-th>
                      <vs-th class="table_th"> EMAIL </vs-th>
                    </template>

                    <template slot-scope="{ data }">
                      <vs-tr
                        :key="indextr"
                        class="table_body"
                        v-for="(tr, indextr) in data"
                      >
                        <vs-td style="text-align: center">
                          <font-awesome-icon
                            v-if="data[indextr].STATUS == '1'"
                            size="2x"
                            style="color: green"
                            :icon="['fas', 'check-circle']"
                          />
                          <font-awesome-icon
                            v-else
                            size="2x"
                            style="color: red"
                            :icon="['fas', 'times-circle']"
                          />
                        </vs-td>

                        <vs-td>
                          <p style="margin-bottom: 5px">
                            <font-awesome-icon :icon="['fas', 'user-circle']" />
                            {{ data[indextr].CODUSUARIO }}
                          </p>

                          <p style="margin-bottom: 11.5px">
                            {{ data[indextr].NOME }}
                          </p>

                          <p
                            style="font-size: 0.85em"
                            v-if="data[indextr].DATAULTIMOACESSO"
                          >
                            <b>Último acesso em: </b>
                            {{ data[indextr].DATAULTIMOACESSO }}
                          </p>

                          <div v-if="data[indextr].STATUS == '1'">
                            <vs-divider />
                            <vs-button
                              style="width: 100%"
                              color="primary"
                              type="filled"
                              @click="getDisconnect(data[indextr].CODUSUARIO)"
                            >
                              <font-awesome-icon
                                style="margin-right: 3.5px"
                                :icon="['fas', 'times-circle']"
                              />
                              DESLIGAR
                            </vs-button>
                          </div>
                          <div v-else style="text-align: center">
                            <vs-divider />
                            <fieldset class="card-off">
                              <b style="color: #9b0e1c">
                                <font-awesome-icon
                                  style="margin-right: 3.5px"
                                  :icon="['fas', 'times-circle']"
                                />
                                DESLIGADO
                              </b>
                            </fieldset>
                          </div>
                        </vs-td>

                        <vs-td>
                          {{ data[indextr].DTNASCIMENTO }}
                        </vs-td>

                        <vs-td>
                          {{ data[indextr].CPF }}
                        </vs-td>

                        <vs-td>
                          {{ data[indextr].EMAIL }}
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </fieldset>
              </vs-col>
            </vs-row>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Management from "../../services/management";

export default {
  name: "Desligamento",
  data: () => ({
    funcInfo: null,
    readyForm: false,
    infoF: [],
    errorInfo: null,
  }),

  methods: {
    getInfoFunc(info) {
      this.$vs.loading({ text: "Carregando..." });
      new Management()
        .getFuncInfo(info)
        .then(({ data }) => {
          this.infoF = Array.isArray(data) ? data : [data];
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorInfo = response.data;
          }
        })
        .finally(() => {
          this.readyForm = true;
          this.$vs.loading.close();
        });
    },

    getDisconnect(user) {
      const payloadDemission = {
        cduFunc: user,
      };

      this.$vs.loading({ text: "Carregando..." });
      new Management()
        .demission(payloadDemission)
        .then(() => {
          this.$vs.loading.close();
          setTimeout(() => {
            this.getInfoFunc(user);
          }, 150);
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorInfo = response.data;
            this.$vs.loading.close();
          }
        });
    },
  },
};
</script>

<style scoped>
.card-off {
  border-width: 3.5px !important;
  border-color: #9b0e1c !important;
}

tr:nth-child(even) {
  background-color: #d4d4d4;
}
</style>
