import Vue from "vue";
import VueRouter from "vue-router";

import Login from "../pages/Login.vue";
import Home from "../pages/Home.vue";

import Ramais from "../pages/Ramais.vue";
import AlunoTurma from "../pages/AlunoTurma.vue";
import TermoCancelamento from "../pages/TermoCancelamento.vue";
import EmpresasConveniadas from "../pages/EmpresasConveniadas.vue"

import Acontecimentos from "../pages/Administrativo/Acontecimentos.vue";
import ProblemasEstruturais from "../pages/Administrativo/ProblemasEstruturais.vue";
import Capacitacao from "../pages/Administrativo/Capacitacao.vue";
import Manutencoes from "../pages/Administrativo/Manutencoes.vue";

import TempoRealComercial from "../pages/Comercial/TempoRealComercial.vue";

import OcupacaoDeSalas from "../pages/Educacional/OcupacaoDeSalas.vue";
import TurmasFuturas from "../pages/Educacional/TurmasFuturas.vue";
import Docentes from "../pages/Educacional/Docentes.vue";
import ProcessoSeletivoDocente from '../pages/Educacional/ProcessoSeletivoDocente.vue';
import ResponsavelFilial from "../pages/Educacional/ResponsavelFilial.vue";
import TempoRealEducacional from "../pages/Educacional/TempoRealEducacional.vue";
import DisciplinasEmProducao from "../pages/Educacional/DisciplinasEmProducao.vue";

import EmissaoDocumento from "../pages/PJRPA/EmissaoDocumento.vue";

import ControleComissao from "../pages/RH/ControleComissão.vue";
import Desligamento from "../pages/RH/Desligamento.vue";

import Atendimentos from "../pages/Modulos/Atendimentos.vue";

import AgendamentoManutencao from "../pages/Modulos/Administracao/AgendamentoManutencao.vue";
import AjustesFinanceiros from "../pages/Modulos/Administracao/AjustesFinanceiros.vue";
import AlteracaoCadastro from "../pages/Modulos/Administracao/AlteracaoCadastro.vue";
import Compras from "../pages/Modulos/Administracao/Compras.vue";
import CorrecaoLancamentosFinanceiros from "../pages/Modulos/Administracao/CorrecaoLancamentos.vue";
import DemandaComercial from "../pages/Modulos/Administracao/DemandaComercial.vue";
import Estoque from "../pages/Modulos/Administracao/Estoque.vue";
import Juridico from "../pages/Modulos/Administracao/Juridico.vue";
import Manutencao from "../pages/Modulos/Administracao/Manutencao.vue";
import NETeP from "../pages/Modulos/Administracao/SolicitacaoNETeP.vue";
import Normativas from "../pages/Modulos/Administracao/Normativas.vue";
import Numerarios from "../pages/Modulos/Administracao/Numerarios.vue";
import ProjetosArquitetonicos from "../pages/Modulos/Administracao/Arquitetonicos.vue";
import RegistroDeConvenio from "../pages/Modulos/Administracao/RegistroDeConvenio.vue";

import ContratacaoPessoal from "../pages/Modulos/Pessoal/ContratacaoPessoal.vue";
import DepartamentoPessoal from "../pages/Modulos/Pessoal/DepartamentoPessoal.vue";
import MovimentacaoPessoal from "../pages/Modulos/Pessoal/MovimentacaoPessoal.vue";
import BeneficioProfessor from "../pages/Modulos/Pessoal/BeneficioDeProfessorInstrutor.vue";
import SolicitacaoBolsa from "../pages/Modulos/Pessoal/Bolsa.vue";
import Treinamento from "../pages/Modulos/Pessoal/Treinamento.vue";

import RegulacaoEscolar from "../pages/Modulos/Educacional/RegulacaoEscolar.vue";
import AjusteDiarioDeClasse from "../pages/Modulos/Educacional/AjusteDiarioDeClasse.vue";
import SolicitacaoDeAlunos from "../pages/Modulos/Educacional/SolicitacaoDeAlunos.vue";
import Certificados from "../pages/Modulos/Educacional/Certificados.vue";
import CursosSuperioresEAD from "../pages/Modulos/Educacional/CursosSuperioresEAD.vue";
import PlanejamentoAcademico from "../pages/Modulos/Educacional/PlanejamentoAcademico.vue";
import ProducaoDeMateriais from "../pages/Modulos/Educacional/ProducaoDeMateriais.vue"

import CriacaoComunicacao from "../pages/Modulos/Marketing/CriacaoComunicacao.vue";
import PublicacaoDeReleases from "../pages/Modulos/Marketing/PublicacaoDeReleases.vue";
import SolicitacaoDeMaterial from "../pages/Modulos/Marketing/SolicitacaoDeMaterial.vue";

import AjusteUsuario from "../pages/Modulos/TI/AjusteDeUsuario.vue";
import AlteracaoProcesso from "../pages/Modulos/TI/AlteracaoProcesso.vue";
import AutomatizacaoProcesso from "../pages/Modulos/TI/AutomatizacaoProcesso.vue";
import CriacaoAtendimento from "../pages/Modulos/TI/CriacaoAtendimentoProtocolo.vue";
import Cubos from "../pages/Modulos/TI/Cubos.vue";
import ErroReportado from "../pages/Modulos/TI/ErrosReportados.vue";
import InfraTelefonia from "../pages/Modulos/TI/InfraETelefonia.vue";
import OutrasSolicitacoes from "../pages/Modulos/TI/OutrasSolicitacoes.vue";
import Relatorios from "../pages/Modulos/TI/Relatorios.vue";
import UnificaRa from "../pages/Modulos/TI/UnificaRaPessoa.vue";

import { clearStepsInformation, isLogged } from "../helpers/storage";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/not-authorized",
      component: () => import("@/pages/NotAuthorized"),
      meta: {
        rule: "*"
      }
    },
    {
      path: "/",
      component: Login,
      meta: {
        isLogin: true,
        rule: "public"
      },
    },
    {
      path: "/home",
      component: Home,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/ramais",
      component: Ramais,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/empresas-conveniadas",
      component: EmpresasConveniadas,
      meta: {        
        rule: "isLogged"
      },
    },    
    {
      path: "/listar-alunos",
      component: AlunoTurma,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/cancelamento",
      component: TermoCancelamento,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/administrativo/acontecimentos",
      component: Acontecimentos,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/administrativo/capacitacao",
      component: Capacitacao,
      meta: {
        rule: "isLogged"
      },
    },
    {
      path: "/administrativo/manutencoes",
      component: Manutencoes,
      meta: {
        rule: "isLogged"
      },
    },
    {
      path: "/administrativo/problemas-estruturais",
      component: ProblemasEstruturais,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/comercial/tempo-real",
      component: TempoRealComercial,
      meta: {        
        rule: "isCommercial"
      },
    },
    {
      path: "/educacional/ocupacao-de-salas",
      component: OcupacaoDeSalas,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/educacional/turmas-futuras",
      component: TurmasFuturas,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/educacional/docentes",
      component: Docentes,
      meta: {        
        rule: "isSecretary"
      },
    },
    {
      path: "/educacional/processo-seletivo-docente",
      component: ProcessoSeletivoDocente,
      meta: {        
        rule: "isManager"
      },
    },
    {
      path: "/educacional/responsavel-filial",
      component: ResponsavelFilial,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/educacional/tempo-real",
      component: TempoRealEducacional,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/educacional/disciplinas-em-producao",
      component: DisciplinasEmProducao,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/rpa/emissao-de-documento",
      component: EmissaoDocumento,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/rh/controle-de-comissao",
      component: ControleComissao,
      meta: {        
        rule: "isOpenRH"
      },
    },
    {
      path: "/rh/desligamento",
      component: Desligamento,
      meta: {        
        rule: "isOpenDesFunc"
      },
    },
    {
      path: "/modulos/atendimentos",
      component: Atendimentos,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/administracao/correcao-lancamentos-financeiros",
      component: CorrecaoLancamentosFinanceiros,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/administracao/demanda-comercial",
      component: DemandaComercial,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/administracao/compras",
      component: Compras,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/administracao/numerarios",
      component: Numerarios,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/administracao/juridico",
      component: Juridico,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/administracao/agendamento-manutencao",
      component: AgendamentoManutencao,
      meta: {        
        rule: "isLogged"
      },
    }, 
    {
      path: "/modulos/atendimentos/administracao/ajustes-financeiros",
      component: AjustesFinanceiros,
      meta: {        
        rule: "isLogged"
      },
    },    
    {
      path: "/modulos/atendimentos/administracao/alteracao-cadastro",
      component: AlteracaoCadastro,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/administracao/estoque",
      component: Estoque,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/administracao/manutencao",
      component: Manutencao,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/administracao/solicitacao-netep",
      component: NETeP,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/administracao/normativas",
      component: Normativas,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/administracao/projetos-arquitetonicos",
      component: ProjetosArquitetonicos,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/administracao/registro-de-convenio",
      component: RegistroDeConvenio,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/pessoal/departamento-pessoal",
      component: DepartamentoPessoal,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/pessoal/bolsa",
      component: SolicitacaoBolsa,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/pessoal/treinamento",
      component: Treinamento,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/pessoal/contratacao-pessoal",
      component: ContratacaoPessoal,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/pessoal/movimentacao-pessoal",
      component: MovimentacaoPessoal,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/pessoal/beneficio-professor-instrutor",
      component: BeneficioProfessor,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/educacional/regulacao-escolar",
      component: RegulacaoEscolar,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/educacional/producao-de-materiais",
      component: ProducaoDeMateriais,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/educacional/ajuste-diario-de-classe",
      component: AjusteDiarioDeClasse,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/educacional/solicitacao-de-alunos",
      component: SolicitacaoDeAlunos,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/educacional/certificados",
      component: Certificados,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/educacional/cursos-superiores-ead",
      component: CursosSuperioresEAD,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/educacional/planejamento-academico",
      component: PlanejamentoAcademico,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/marketing/criacao-comunicacao",
      component: CriacaoComunicacao,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/marketing/publicacao-de-releases",
      component: PublicacaoDeReleases,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/marketing/solicitacao-de-material",
      component: SolicitacaoDeMaterial,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/ti/ajuste-de-usuario",
      component: AjusteUsuario,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/ti/alteracao-de-processo",
      component: AlteracaoProcesso,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/ti/automatizacao-de-processo",
      component: AutomatizacaoProcesso,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/ti/criacao-de-atendimento",
      component: CriacaoAtendimento,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/ti/cubos",
      component: Cubos,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/ti/reportar-erro",
      component: ErroReportado,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/ti/infraestrutura-telefonia",
      component: InfraTelefonia,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/ti/outras-solicitacoes",
      component: OutrasSolicitacoes,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/ti/relatorios",
      component: Relatorios,
      meta: {        
        rule: "isLogged"
      },
    },
    {
      path: "/modulos/atendimentos/ti/unificacao-de-ra",
      component: UnificaRa,
      meta: {        
        rule: "isLogged"
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.clearStepsInformation) {
    clearStepsInformation();
  }

  function navigateToNextPageOrFallback(condition, nextFallbackParams) {
    if (condition) {
      next();
    } else {
      next({ ...nextFallbackParams });
    }
  }

  const { isLogin } = to.meta;

  if (isLogin) {
    navigateToNextPageOrFallback(!isLogged(), {
      path: "/home",
    });
  } else {
    navigateToNextPageOrFallback(isLogged(), {
      path: "/",
      params: { nextUrl: to.fullPath },
    });
  }
});

export default router;