<template>
  <section>
    <div class="subContainer">
      <vs-row vs-w="12" class="topo">
        <div class="titulo">
          <font-awesome-icon :icon="['fas', 'graduation-cap']" />
          <h2 class="tituloAtendimento">Ajuste de Professor/Instrutor</h2>
        </div>
      </vs-row>

      <div>
        <ValidationObserver ref="observer2" v-slot="{ invalid }">
          <vs-row class="pd-t">
            <vs-col>
              <fieldset class="search">
                <legend>Cód. Turma <b style="color: red">*</b></legend>
                <ValidationProvider name="Cód. Turma" rules="required|numeric">
                  <vs-input
                    @keyup.enter="getSubjectByClassCode()"
                    v-model="classCode"
                    placeholder="Digite o Cód. Turma"
                    class="selectExampleRA"
                  />
                </ValidationProvider>
                <vs-button
                  color="primary"
                  class="buscar"
                  type="filled"
                  :disabled="invalid"
                  @click="getSubjectByClassCode()"
                >
                  <font-awesome-icon :icon="['fas', 'search']" />
                  <span> Buscar </span></vs-button
                >
              </fieldset>
            </vs-col>
          </vs-row>
        </ValidationObserver>
      </div>

      <div>
        <vs-row class="pt-d">
          <vs-col>
            <Message
              class="pd-t"
              :msg="idAttendance"
              :error="errorIdAttendance"
              v-if="idAttendance || errorIdAttendance"
            />
          </vs-col>
        </vs-row>
      </div>

      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <div v-if="responseClassCode != null">
          <div v-if="responseClassCode == ''">
            <p class="validacao">Cód. Turma Inválido</p>
          </div>
          <div v-else>
            <vs-row class="pd-table">
              <vs-col>
                <fieldset>
                  <ValidationProvider name="Disciplina" rules="required">
                    <vs-table
                      class="table_intranet"
                      hoverFlat
                      multiple
                      v-model="selected"
                      :data="responseClassCode"
                      v-on:input="validateSelectedDisciplines"
                    >
                      <template slot="thead">
                        <vs-th> Disciplina </vs-th>
                        <vs-th> Inicio </vs-th>
                      </template>

                      <template slot-scope="{ data }">
                        <vs-tr
                          :data="tr"
                          :key="indextr"
                          v-for="(tr, indextr) in data"
                          @click="handleDisciplineSelection(tr)"
                        >
                          <vs-td>
                            {{ data[indextr].DISCIPLINA }} <br />
                            <div style="margin-top: 8.5px">
                              <font-awesome-icon
                                :icon="['fas', 'user-circle']"
                              />
                              {{
                                data[indextr].TITULAR
                                  ? data[indextr].TITULAR 
                                  : "-"
                              }}
                            </div>
                          </vs-td>

                          <vs-td>
                            {{ data[indextr].DATA_INICIAL }}
                          </vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>
                    Data de Troca do Professor <b style="color: red">*</b>
                  </legend>
                  <ValidationProvider
                    name="Data de Troca do Professor"
                    rules="required"
                  >
                    <vs-input
                      class="selectExampleDouble"
                      v-model="exchangeDate"
                      type="date"
                    />
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <ValidationProvider
                  name="Justificativa"
                  rules="required|max:500"
                >
                  <vs-textarea
                    label="Justificativa *"
                    height="210px"
                    rows="11"
                    class="textArea"
                    counter="500"
                    v-model="solicitation"
                  />
                </ValidationProvider>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col style="text-align: center">
                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click="resetForm()"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  Cancelar
                </vs-button>

                <vs-button
                  class="button-protocol"
                  color="success"
                  type="gradient"
                  @click="createAttendance()"
                  :disabled="invalid"
                >
                  <font-awesome-icon :icon="['fas', 'check']" />
                  Gerar
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </section>
</template>

<script>
import Classes from "../../../../services/classes";
import Teachers from "../../../../services/teachers";
import {
  convertDate,
  getLoginDataFromStorage,
} from "../../../../helpers/storage.js";
import Attendance from "../../../../services/attendance";
import EducacionalModel from "../../../../models/educacional/educacional.js";

export default {
  name: "AjusteDeProfessorInstrutor",
  data: () => ({
    activeTeachers: null,
    errorActiveTeachers: null,
    exchangeDate: null,
    classCode: null,
    responseClassCode: null,
    errorResponseClassCode: null,
    selected: [],
    solicitation: null,
    idAttendance: null,
    errorIdAttendance: null,
    nameTeacher: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando..." });
    new Teachers()
      .getActiveTeachers()
      .then(({ data }) => {
        this.activeTeachers = Array.isArray(data) ? data : [data];
        this.$vs.loading.close();
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorActiveTeachers = response.data;
          console.log(response);
        }
        this.$vs.loading.close();
      });
  },

  methods: {
    inputChange(event) {
      return event.target.value;
    },

    getSubjectByClassCode() {
      this.selected = [];
      this.solicitation = null;
      this.exchangeDate = null;

      this.$vs.loading({ text: "Carregando..." });
      new Classes()
        .getSubjectByClassCode(this.classCode)
        .then(({ data }) => {
          this.responseClassCode = Array.isArray(data) ? data : [data];
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorResponseClassCode = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      const { ...userProfile } = getLoginDataFromStorage();

      const personId = userProfile.userData.CODIGO;
      const personLocal = userProfile.userData.CODLOCAL;
      const personName = userProfile.userData.NOME;
      const attendantId = userProfile.userData.CODATENDENTE;

      const payload = new EducacionalModel().getDataByAttendanceId("E1-4");

      payload.data.locationId = personLocal.toString();
      payload.data.personId = personId.toString();
      payload.data.requestText = `USUÁRIO: ${personName} - ${personId}\nCÓDIGO TURMA: ${
        this.classCode
      }\nDISCIPLINA: ${this.validateSelectedDisciplines()}\nDATA DE TROCA: ${convertDate(
        this.exchangeDate
      )}\nPROFESSOR: ${this.selected[0].TITULAR ? this.selected[0].TITULAR : '-'}\nTEXTO SOLICITAÇÃO: ${
        this.solicitation
      }`;
      payload.data.attendantId = attendantId.toString();

      this.$vs.loading({ text: "Carregando..." });
      new Attendance()
        .createAttendance(payload.data)
        .then(({ data }) => {
          this.idAttendance = data;
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorIdAttendance = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.resetForm();
          this.$vs.loading.close();
        });
    },

    validateSelectedDisciplines() {
      if (!this.selected || this.selected.length === 0) return;

      const selectedTitular = this.selected[0].TITULAR;

      for (let i = 0; i < this.selected.length; i++) {
        const discipline = this.selected[i];

        if (!discipline.TITULAR) continue;

        if (discipline.TITULAR !== selectedTitular) {
          this.selected = [discipline];
          break;
        }
      }

      if (this.selected.some((d) => !d.TITULAR)) {
        this.selected = this.selected.filter((d) => !d.TITULAR);
      }

      let text = "";
      for (let i = 0; i < this.selected.length; i++) {
        text += this.selected[i].DISCIPLINA+",";
      }
      return text;
    },

    handleDisciplineSelection(discipline) {
      if (!discipline.TITULAR) {
        this.selected = [discipline];
        this.selected = this.selected.filter(d => !d.TITULAR);
      } else {
        const selectedTitular = this.selected.length
          ? this.selected[0].TITULAR
          : null;

        if (!selectedTitular || selectedTitular === discipline.TITULAR) {
          this.selected = [discipline];
        } else {
          this.selected = [discipline];
        }
      }
    },

    resetForm() {
      this.selected = [];
      this.solicitation = null;
      this.exchangeDate = null;
      this.classCode = null;
      this.responseClassCode = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
        this.$refs.observer2.reset();
      });
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 480px) {
  .buscar span {
    display: none;
  }
  .buscar {
    width: 3em;
    margin-left: 0.7em;
  }
}
</style>
