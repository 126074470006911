import { https } from './api';

export default class {
    constructor() {
        this.instance = https('management');
    }
    
    getListOfStructuralProblems = (codUsuario) => {
        return this.instance({
            method: 'GET',
            url: `/structuralProblems/${codUsuario}`,
            timeout: 30000,
        });
    }    

    getAssociatedCompanies = () =>{
        return this.instance({
            method: 'GET',
            url: `/associatedCompanies`,
            timeout: 30000,  
        });
    }

    getMaintenanceSchedule = () =>{
        return this.instance({
            method: 'GET',
            url: `/maintenanceschedule`,
            timeout: 30000,  
        });
    }

    getNumberOfCalls = (codAtendente) => {
        return this.instance({
            method: 'GET',
            url: `/attendance/${codAtendente}/numberOfCalls`,
            timeout: 30000,
        });
    }

    getCallsInTheUserQueue = (codUser) =>{
        return this.instance({
            method: 'GET',
            url: `/callsInTheUserQueue/${codUser}`,
            timeout: 30000,  
        });
    }

    getCallsInLine = (codAtendenteResp) => {
        return this.instance({
            method: 'GET',
            url: `/callsInLine/${codAtendenteResp}`,
            timeout: 30000,
        })
    }

    getCallsByDepartment = (codAtendente, codAtendenteResp) => {
        return this.instance({
            method: 'GET',
            url: `/callsByDepartment/${codAtendente}/${codAtendenteResp}`,
            timeout: 30000,
        });
    }

    getFunctions = () => {
        return this.instance({
            method: 'GET',
            url: `/functions`,
            timeout: 30000,
        });
    }

    getFields = () => {
        return this.instance({
            method: 'GET',
            url: `/fields`,
            timeout: 30000,
        });
    }
     
    getAgreementByRA = (RA) => {
        return this.instance({
            method: 'GET',
            url: `/agreementByRa/${RA}`,
            timeout: 30000,
        });
    }

    getAgreementByCodClient = (codCliente) => {
        return this.instance({
            method: 'GET',
            url: `/agreementByCodCliente/${codCliente}`,
            timeout: 30000,
        })
    }
    
    getReturnAndChargeback = (nAtendimento) => {
        return this.instance({
            method: 'GET',
            url: `/returnAndChargeback/${nAtendimento}`,
            timeout: 30000,
        })
    }

    getProductionDisciplines = () => {
        return this.instance({
            method: 'GET',
            url: `/productionDisciplines`,
        })
    }

    getTrainingSchedule = (idPearson) => {
        return this.instance({
            method: 'GET',
            url: `/trainingSchedule/${idPearson}`,            
        })
    }

    getDisciplineByCourseCode = (codCourse) => {
        return this.instance({
            method: 'GET',
            url: `/disciplineByCourseCode/${codCourse}`,            
        })
    }

    getDisciplineEmenta = (ra, codCourse) => {
        return this.instance({
            method: 'GET',
            url: `/disciplineEmenta/${ra}/${codCourse}`,            
        })
    }

    register = (data) => {
        return this.instance({
            method: 'POST',
            url: `/trainning/register`,
            data            
        })
    }

    getListOfCubes = () => {
        return this.instance({
            url: `/listOfCubes`,
            method: 'GET',
        })
    }

    getListOfReports = () => {
        return this.instance({
            method: 'GET',
            url: `/listOfReports`,
        })
    }

    getCommissionRH = (mes, ano) => {
        return this.instance({
            method: 'GET',
            url: `/commissionRH/${mes}/${ano}`,            
        })
    }

    getFuncInfo = (info) => {
        return this.instance({
            method: 'GET',
            url: `/funcInfo/${info}`,            
        })
    }

    demission = (data) => {
        return this.instance({
            method: 'POST',
            url: `/demission`,
            data            
        })
    }
}

