<template>
  <div>
    <div class="parentx">
      <vs-sidebar
        hidden-background
        static-position
        color="primary"
        class="sidebarx"
        spacer
        v-model="active"
      >
        <div class="header-sidebar" slot="header">
          <vs-avatar
            v-if="this.image != null"
            size="70px"
            :src="`data:image/png;base64,${this.image}`"
          />
          <vs-avatar v-else size="70px"></vs-avatar>
          <h4>
            {{ this.nome }}

            <vs-tooltip class="logout" text="Sair" position="top" >
              <a style="color: #9b0e1c;" @click="logout()">
                <font-awesome-icon :icon="['fas', 'sign-out-alt']"/>
              </a>
            </vs-tooltip>
          </h4>

          <div v-if="addSpecialLogin == true">
            <vs-divider/>
            <vs-input
              style="margin-top: 18.5px !important; width: 100% !important;"
              v-model="userSpecial"
              label-placeholder="Usuário"
              @keyup.enter="specialLogin"
            />
          </div>
        </div>

        <vs-sidebar-item index="1" style="margin-left: -15px;">
          <a style="font-size: 15px; margin: auto;" href="/home">
            <font-awesome-icon :icon="['fas', 'home']"/>
            <p style="margin-left: 3.5px;">Início</p>
          </a>

          <a style="font-size: 15px; margin: auto;" href="/modulos/atendimentos">
            <font-awesome-icon :icon="['fas', 'headset']"/>
            <p style="margin-left: 3.5px;">Atendimentos</p>
          </a>
        </vs-sidebar-item>

        <vs-divider/>

        <vs-sidebar-group
          v-for="(item, index) in menu"
          :key="index"
          :title="item.header"
          v-show="item.rule ? $acl.check(item.rule) : true"
        >
          <div class="vs-group">
            <vs-sidebar-item
              v-for="(submenu, indexSubMenu) in item.submenu"
              :key="indexSubMenu"
              :index="submenu.index"
              class="vs-item"
              v-show="submenu.rule ? $acl.check(submenu.rule) : true"
            >
              <font-awesome-icon style="width: 18.5px; margin-right: -2.5px" :icon="submenu.icon" />
              <a :href="submenu.url">{{ submenu.title }}</a>
            </vs-sidebar-item>
          </div>
        </vs-sidebar-group>
      </vs-sidebar>
    </div>
  </div>
</template>

<script>
import LoginService from "../../services/auth";
import { saveDataInStorage } from "../../helpers/storage";
import { bus } from "../../main";
import navBarMenu from "../../models/menus/menuNavBarAtendimentos";
import {
  logoffUserAndEraseData,
  isLogged as isLoggedHelper,
  getLoginDataFromStorage,
  nameFormat,
} from "../../helpers/storage";

export default {
  name: "SideBarAtendimentos",
  data: () => ({
    userSpecial: "",
    addSpecialLogin: false,
    nome: null,
    image: null,
    active: true,
    menu: [],
  }),

  mounted() {
    this.menu = new navBarMenu().getData();
  },

  created() {
    const { ...user } = getLoginDataFromStorage();
    const user1 = user.userData;
    this.nome = nameFormat(user1.NOME);
    this.image = user1.IMAGEM;

    const profileDBA = user.profiles.find(element => element == "DBA");

    if(profileDBA) {
      this.addSpecialLogin = true;
    } else {
      this.addSpecialLogin = false;
    }
  },

  methods: {
    specialLogin() {
      this.logout();

      this.$vs.loading({ text: "Carregando..." });
      new LoginService()
        .loginSpecial({
          username: this.userSpecial
        })
        .then((res) => {
          saveDataInStorage(res.data);
          bus.$emit("login");
          this.$vs.loading.close();
          this.$router.push("/home");
        })
        .catch((error) => {
          const { response } = error;
          this.error = response?.data?.error || "Tente novamente mais tarde.";
          this.$vs.loading.close();
        });
    },
    
    logout() {
      logoffUserAndEraseData();
      this.isLogged = isLoggedHelper();
      this.steps = [];
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: black;
}

.parentx {
  z-index: 3; 
  height: 100%;
  width: 230px !important;
  box-shadow: 0.5px 1.5px 5px #888888;
  top: 50px;
  position: fixed;
}

.sidebarx {
  height: 93.5%;
}

.vs-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.vs-item {
  margin: -12.5px;
  margin-left: -20px;
}

.ra-copied {
  width: auto;
  border-radius: 70px;
  margin-top: 3.5%;
}
  
.header-sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
  
h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.logout {
  margin: 1.5px 0 0 7.5px;
}

@media screen and (max-height: 1000px) {
  .parentx {
    width: 210px;
  }
}

@media screen and (max-height: 800px) {
  .sidebarx {
    height: 93%;
  }
}
</style>