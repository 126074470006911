<template>
  <section>
    <Navbar />
    <div class="columns">
      <SideBarHome class="menu" />
      <div class="container">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'business-time']" />
            <h2 class="tituloAtendimento">Controle de Comissão</h2>
          </div>
        </vs-row>

        <div>
          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <legend>Selecione o Período <b style="color: red;">*</b></legend>
                <vs-col vs-w="8" vs-sm="12">
                  <vs-select
                    label="Mês da Comissão"
                    style="width: 100%;"
                    v-model="mesSelecionado"
                    placeholder="Selecione um Mês"
                  >
                    <vs-select-item
                      :key="item.meses"
                      :value="item.value"
                      :text="item.text"
                      v-for="item in meses"
                    />
                  </vs-select>
                </vs-col>
                <vs-col vs-w="4" vs-sm="12">
                  <vs-select
                    label="Ano da Comissão"
                    style="width: 100%;"
                    v-model="anoSelecionado"
                    placeholder="Selecione um Ano"
                  >
                    <vs-select-item
                      :key="item.anos"
                      :value="item.value"
                      :text="item.text"
                      v-for="item in anos"
                    />
                  </vs-select>
                </vs-col>
                <vs-col>
                  <vs-button style="width: 100%; margin-top: 15px;" color="#008000" type="filled" @click="confirmPopup = true">
                    <font-awesome-icon style="margin-right: 3.5px;" :icon="['fas', 'coins']" />
                    Verificar Comissionamento
                  </vs-button>
                </vs-col>
              </fieldset>
            </vs-col>
          </vs-row>
        </div>

        <vs-popup title="Um momento..." :active.sync="confirmPopup">
          <vs-card outlined>
            <p style="font-size: 1.25em; text-align: justify;">
              Este processo buscará a comissão de todo o comercial no
              RM. Isto poderá levar alguns minutos, então após clicar em
              <b>Confirmar</b> aguarde até o processo concluir.
            </p>
            
            <vs-divider/>

            <div class="confirm-integrate">
              <vs-button @click="confirmPopup = false">
                <font-awesome-icon :icon="['fas', 'times-circle']" />
                Cancelar
              </vs-button>
              
              <vs-button
                class="button-integrate"
                color="success"
                type="gradient"
                @click="findBy()"
              >
                <font-awesome-icon :icon="['fas', 'check']" />
                Confirmar
              </vs-button>
            </div>
          </vs-card>
        </vs-popup>

        <div>
          <vs-row style="margin-left: 15px;" v-if="this.commissionRH.length == 0 || this.commissionRH[0] == ''">
            <p>Nenhuma informação encontrada</p>
          </vs-row>

          <vs-row v-else class="pd-table">
            <vs-col>
              <fieldset>
                <vs-table
                  class="table_intranet"
                  max-items="100"
                  hoverFlat
                  search
                  :data="commissionRH"
                >
                  <template slot="thead">
                    <vs-th class="table_th"> Filial </vs-th>
                    <vs-th class="table_th"> Colaborador </vs-th>
                    <vs-th class="table_th"> Ticket Médio </vs-th>
                    <vs-th class="table_th"> Comissão </vs-th>
                    <vs-th class="table_th"> Premiação </vs-th>
                    <vs-th class="table_th"> Comissão 2 </vs-th>
                    <vs-th class="table_th"> Premiação 2 </vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr
                      :key="indextr"
                      class="table_body"
                      v-for="(tr, indextr) in data"
                    >
                      <vs-td>
                        {{ data[indextr].LOCALIDADE }}
                      </vs-td>

                      <vs-td>
                        {{ data[indextr].COLABORADOR }} <br><br>
                        <span>
                          <font-awesome-icon :icon="['fas', 'id-badge']" />
                          {{ data[indextr].CHAPA }}
                        </span>
                        <vs-divider/>
                        1ª Quinzena: {{ data[indextr].P_QUINZENA_MAT_META > 0 ? data[indextr].P_QUINZENA_MAT : '-' }} <br>
                        2ª Quinzena: {{ data[indextr].S_QUINZENA_MAT_META > 0 ? data[indextr].S_QUINZENA_MAT : '-' }} <br>
                        Total de Matrícula(s): {{ data[indextr].QTDPREMATRICULA }} <br><br>
                        1ª Quinzena: <b>{{ data[indextr].P_QUINZENA_MAT_META > 0 ? Number(data[indextr].P_QUINZENA_META).toFixed(2) + '%' : '-' }}</b> <br>
                        2ª Quinzena: <b>{{ data[indextr].S_QUINZENA_MAT_META > 0 ? Number(data[indextr].S_QUINZENA_META).toFixed(2) + '%' : '-' }}</b> <br>
                        Média das Quinzenas: <b>{{ Number(data[indextr].MEDIA).toFixed(2) }}%</b>
                      </vs-td>

                      <vs-td>
                        <span v-if="data[indextr].TICKETMEDIO">
                          R${{ Number(data[indextr].TICKETMEDIO).toFixed(2) }} (<b>{{ data[indextr].NMATRICULASTICKET }}</b>)
                        </span>
                        <span v-else>
                          R$0.00
                        </span>
                      </vs-td>

                      <vs-td>
                        <span v-if="data[indextr].COMISSAO">
                          R${{ Number(data[indextr].COMISSAO).toFixed(2) }}
                        </span>
                        <span v-else>
                          R$0.00
                        </span>
                      </vs-td>

                      <vs-td>
                        <span v-if="data[indextr].PREMIACAO">
                          R${{ Number(data[indextr].PREMIACAO).toFixed(2) }}
                        </span>
                        <span v-else>
                          R$0.00
                        </span>
                      </vs-td>

                      <vs-td>
                        <span v-if="data[indextr].COMISSAO2">
                          R${{ Number(data[indextr].COMISSAO2).toFixed(2) }}
                        </span>
                        <span v-else>
                          R$0.00
                        </span>
                      </vs-td>

                      <vs-td>
                        <span v-if="data[indextr].PREMIACAO2">
                          R${{ Number(data[indextr].PREMIACAO2).toFixed(2) }}
                        </span>
                        <span v-else>
                          R$0.00
                        </span>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </fieldset>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Management from "../../services/management";

export default {
  name: "ControleComissao",
  data: () => ({
    mesSelecionado: null,
    anoSelecionado: null,
    meses: [
      { text: "Janeiro", value: 1 },
      { text: "Fevereiro", value: 2 },
      { text: "Março", value: 3 },
      { text: "Abril", value: 4 },
      { text: "Maio", value: 5 },
      { text: "Junho", value: 6 },
      { text: "Julho", value: 7 },
      { text: "Agosto", value: 8 },
      { text: "Setembro", value: 9 },
      { text: "Outubro", value: 10 },
      { text: "Novembro", value: 11 },
      { text: "Dezembro", value: 12 },
    ],
    anos: [
      { text: "2024", value: 2024 },
      { text: "2025", value: 2024 },
    ],
    commissionRH: [],
    confirmPopup: false,
    errorCommissionRH: null
  }),
  mounted() {
    this.date = new Date();
    var currentMonth = this.date.getMonth() + 1;
    var currentYear = this.date.getFullYear();
    this.mesSelecionado = currentMonth;
    this.anoSelecionado = currentYear;
  },

  methods: {
    async findBy() {
      console.log(this.mesSelecionado, this.anoSelecionado);
      this.confirmPopup = false;
      this.$vs.loading({ text: "Carregando..." });
      new Management()
        .getCommissionRH(this.mesSelecionado, this.anoSelecionado)
        .then(({ data }) => {
          this.commissionRH = Array.isArray(data) ? data : [data];
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorCommissionRH = response.data;
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    }
  }
};
</script>

<style scoped>
h3,
p {
  margin-bottom: 1em;
}

.confirm-integrate {
  text-align: center;
}

.button-integrate {
  margin-left: 3.5%;
}

.divSelect {
  padding: 1em;
}

tr:nth-child(even) {
  background-color: #D4D4D4;
}
</style>
